import { Component, OnInit, } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { AlertService, AuthenticationService, UserService } from '../../services/index';

@Component({
  selector: 'app-setPassword',
  templateUrl: './setPassword.component.html',
  //styleUrls: ['./setPassword.component.css']
})
export class SetPasswordComponent implements OnInit {
  hide = true;
  loading = false;
  submitted = false;
  showPasswordRules: boolean = false;
  returnUrl: string;
  closeResult: string;
  errorMessagePassMisMatch: string;
  errorMessageLogin: string;
  registrationSuccess: boolean = false;
  token = '';
  setPasswordForm: FormGroup;
  showSpinner;
  passwordHasUpperCase: boolean = false;
  passwordHasLowerCase: boolean = false;
  passwordHasNumber: boolean = false;
  passwordHasSpecialCharacter: boolean = false;
  passwordHasLength: boolean = false;
  passwordValid: boolean = false;
  errorMessagePassChanged : string;
  registerFormSubmitted: any;
  disableButton:boolean = false;


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private modalService: NgbModal
  ) { }

  open(content) {
    this.modalService.dismissAll();
    this.modalService.open(content, { centered: true, size: 'lg', windowClass: 'modal-holder' });
  }

  ngOnInit() {
    this.token = this.route.snapshot.params['token'];

    this.userService.getTokenValidity({ token: this.token })
      .pipe(first())
      .subscribe(
        data => {
        },
        error => {
          this.loading = false;
          this.alertService.error('Invalid Token', true);
          this.router.navigate(['/login']);
        });

    this.setPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")]],
      confirmPassword: ['', Validators.required]
    });
    // reset login status
    this.authenticationService.logout();
  }

  get sp() { return this.setPasswordForm.controls; }


  setPassword() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.setPasswordForm.invalid) {
      return;
    } else {
      if (this.setPasswordForm.value.password !== this.setPasswordForm.value.confirmPassword) {
        this.errorMessagePassMisMatch = "Password & confirm password did not match!!!";
        return false;
      }
    }

    let FormDetails = this.setPasswordForm.value;
    FormDetails.token = this.token;
    this.loading = true;
    this.userService.setPassword(FormDetails)
      .pipe(first())
      .subscribe(
        data => {
          this.disableButton = true;
          this.errorMessagePassChanged = 'Your password has been saved successfully. We are redirecting you to login page. Please login!!!';
          //this.alertService.success('Passsword set Successfully', true);
          setTimeout(() => {
            this.router.navigate(['/login']);
            }, 7000);
          },
        error => {
          this.errorMessagePassMisMatch = error;
          this.loading = false;
        });
  }
  get pwdform() { return this.setPasswordForm.controls; }
  validatePassword() {
    if (this.setPasswordForm.value && this.setPasswordForm.value.password) {
      this.passwordHasUpperCase = this.setPasswordForm.value.password.match(/[A-Z]/) && this.setPasswordForm.value.password.match(/[A-Z]/).index >= 0 ? true : false;
      this.passwordHasLowerCase = this.setPasswordForm.value.password.match(/[a-z]/) && this.setPasswordForm.value.password.match(/[a-z]/).index >= 0 ? true : false;
      this.passwordHasNumber = this.setPasswordForm.value.password.match(/[0-9]/) && this.setPasswordForm.value.password.match(/[0-9]/).index >= 0 ? true : false;
      this.passwordHasSpecialCharacter = this.setPasswordForm.value.password.match(/[!@#$%^&*()_+~]/) && this.setPasswordForm.value.password.match(/[!@#$%^&*()_+~]/).index ? true : false;
      this.passwordHasLength = this.setPasswordForm.value.password.length >= 8 ? true : false;
      if (this.passwordHasUpperCase && this.passwordHasLowerCase && this.passwordHasNumber
        && this.passwordHasSpecialCharacter && this.passwordHasLength) {
        this.passwordValid = true;
      }
      else {
        this.passwordValid = false;
      }
    }
    else {
      this.passwordHasUpperCase = false;
      this.passwordHasLowerCase = false;
      this.passwordHasNumber = false;
      this.passwordHasSpecialCharacter = false;
      this.passwordHasLength = false;
      this.passwordValid = false;
    }

  }

  onConfPass(event) {
    if (this.setPasswordForm.get('password').value !== event) {
      this.setPasswordForm.get('confirmPassword').setErrors( {MatchPassword: true} )
      return false;
    }
  }

}




