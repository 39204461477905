import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { SetPasswordComponent } from './login/setPassword/setPassword.component'

import { AuthGuard } from './guards/auth.guard';

const appRoutes: Routes = [
  // { path: 'supplier', component: SupplierComponent, /*canActivate: [AuthGuard]*/ },
  
  { path: 'dashboard', loadChildren: './supplier/supplier.module#SupplierModule', },
  { path: 'home', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'setPassword/:token', component: SetPasswordComponent },

  { path: '', redirectTo: '/home', pathMatch: 'full'},
  // otherwise redirect to home
  { path: '**', redirectTo: 'home' }
];


@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

// export const routing = RouterModule.forRoot(appRoutes);
export class routing { }
