import { NgModule } from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { routing } from './app.route';
import { AuthGuard } from './guards/auth.guard';
import { CustomMaterialModule } from './core/material.module';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { AlertService, AuthenticationService, UserService } from './services/index';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import {NgxMaskModule} from 'ngx-mask'
import { SetPasswordComponent } from './login/setPassword/setPassword.component'
// import { fakeBackendProvider } from './helpers/temp-data';
// import { AlertComponent } from './alert/alert.component';
// import { NotifierModule } from 'angular-notifier';
// import { DataService } from './services/data.service';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';




@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    AppComponent,
    SetPasswordComponent,
    // AlertComponent,
  ],
  imports: [
    NgxMaskModule.forRoot(),
    BrowserAnimationsModule,
    CustomMaterialModule,
    FormsModule,
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule,
    // BrowserModule,
    // FontAwesomeModule,
   /*  NotifierModule.withConfig( {
      position: {
        horizontal: {
          position: 'middle',
          distance: 140
        },
        vertical: {
          distance: 140
        }
      }
    } ), */
    routing
  ],
  providers: [
     AuthGuard,
     AlertService,
     AuthenticationService,
     UserService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // fakeBackendProvider,
    //  DataService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
