import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { Response } from '@angular/http'
import { User } from '../model/user';
import { Observable } from 'rxjs';


let userID;
let registerDetails;
let companyExsistingDetails;
let financeExsistingDetails;
let serviceExsistingDetails;
let diversityExsistingDetails;
let programExsistingDetails;
let refferExsistingDetails;
let userData;
let contactExsistingDetails;

@Injectable()
export class UserService {
    serviceUrl = environment.serviceUrl;

    percentComplete: number = 0;
    profileComplete: boolean = false;

    constructor(private http: HttpClient) { }


    setUserID(uuid) {
        //userID = uuid;
        sessionStorage.setItem('userID', uuid);
    }

    getUserID() {
        userID = sessionStorage.getItem('userID');
        return userID;
    }

    getUserDetails() {
        // debugger
        userData = JSON.parse(sessionStorage.getItem('currentUser'));
        userData.firstName = userData.register.personName.replace(/ .*/, '');
        userData.lastName = userData.register.personName.match(/\w+$/)[0];
        userData.personName = userData.register.personName;
        userData.companyName = userData.register.companyName;
        this.percentComplete = this.getCompletionPercentage(userData);
        return userData;
        // let userData = {
        //     "_id" : "5c50263fc79efe4be48c9092",
        //     "companyName" : "ChandrakalSimplifyWorkforce",
        //     "personName" : "Chandrakala",
        //     "emailId" : "chandrakala@simlifyworkforce.com",
        //     "phoneNumber" : "9999999999",
        //     "password" : "$2b$10$QI0.cI2q.wfPTkljByXBDO1lWw86I2mP7ZnS5SWptRzhazWZomBHa",
        //     "__v" : 0
        // }
        // return  userData;
    }

    getCompletionPercentage(userData) {
        let percentComplete: number = 0;
        if (userData && userData.companyDetails && userData.companyDetails != "Not Found") { percentComplete += 25; }
        if (userData && userData.financeDetails && userData.financeDetails != "Not Found") { percentComplete += 25; }
        if (userData && userData.diversityDetails && userData.diversityDetails != "Not Found") { percentComplete += 25; }
        if (userData && userData.referDetails && userData.referDetails != "Not Found") { percentComplete += 25; }
        return percentComplete;
    }

    getProfileCompletionPercentage() {
        return this.percentComplete;
    }

    register(user: User) {
        return this.http.post(this.serviceUrl + `registration`, user);
    }

    sendMail(user: User) {
        return this.http.post(this.serviceUrl + `mailer`, user);
    }

    forgotPassword(user: User) {
        return this.http.post(this.serviceUrl + `forgotPassword`, user);
    }

    setPassword(user: User) {
        return this.http.post(this.serviceUrl + `setPassword`, user);
    }

    getTokenValidity(token: any) {
        return this.http.post(this.serviceUrl + `validity`, token);
    }

    supplier(user: User) {
        var userDetail = JSON.parse(sessionStorage.getItem('currentUser'));
        userDetail.companyDetails = user;
        sessionStorage.setItem('currentUser', JSON.stringify(userDetail));
        this.percentComplete = this.getCompletionPercentage(userDetail);
        return this.http.post(this.serviceUrl + `supplier`, user);
    }

    finance(user: User) {
        var userDetail = JSON.parse(sessionStorage.getItem('currentUser'));
        userDetail.financeDetails = user;
        sessionStorage.setItem('currentUser', JSON.stringify(userDetail));
        this.percentComplete = this.getCompletionPercentage(userDetail);
        return this.http.post(this.serviceUrl + `finance`, user);
    }

    service(user: User) {
        return this.http.post(this.serviceUrl + `service`, user);
    }

    diversity(user: User) {
        var userDetail = JSON.parse(sessionStorage.getItem('currentUser'));
        userDetail.diversityDetails = user;
        sessionStorage.setItem('currentUser', JSON.stringify(userDetail));
        this.percentComplete = this.getCompletionPercentage(userDetail);
        return this.http.post(this.serviceUrl + `diversity`, user);
    }

    program(user: User) {
        return this.http.post(this.serviceUrl + `program`, user);
    }

    reffer(user: User) {
        var userDetail = JSON.parse(sessionStorage.getItem('currentUser'));
        userDetail.referDetails = user;
        sessionStorage.setItem('currentUser', JSON.stringify(userDetail));
        this.percentComplete = this.getCompletionPercentage(userDetail);
        return this.http.post(this.serviceUrl + `reffer`, user);
    }

    uploadDocument(user: User) {
        return this.http.post(this.serviceUrl + `api/file/upload`, user);
    }

    getUserFileUrl(fileKey) {
        return this.serviceUrl + 'api/file/' + fileKey;
    }

    setCompanyDetails(companyDetails) {
        companyExsistingDetails = companyDetails;
    }

    getCompanyDetails() {
        return companyExsistingDetails;
    }

    setFinanceDetails(financeDetails) {
        financeExsistingDetails = financeDetails;
    }

    getFinanceDetails() {
        return financeExsistingDetails;
    }

    setServiceDetails(serviceDetails) {
        serviceExsistingDetails = serviceDetails;
    }

    getServiceDetails() {
        return serviceExsistingDetails;
    }
    setDiversityDetails(diversityDetails) {
        diversityExsistingDetails = diversityDetails;
    }

    getDiversityDetails() {
        return diversityExsistingDetails;
    }
    setProgramDetails(programDetails) {
        programExsistingDetails = programDetails;
    }

    getProgramDetails() {
        return programExsistingDetails;
    }
    setRefferDetails(referDetails) {
        refferExsistingDetails = referDetails;
    }

    getRefferDetails() {
        return refferExsistingDetails;
    }
    setRegisterDetails(register) {
        registerDetails = register;
    }

    getRegisterDetails() {
        return registerDetails;
    }

    setContactDetails(contacts) {
        contactExsistingDetails = contacts;
    }

    getContactDetails() {
        return contactExsistingDetails;
    }

    setPercentile(user) {
        console.log(this.serviceUrl + `setPercentile`, user);
        return this.http.post(this.serviceUrl + `setPercentile`, user);
    }

    getSuppliersList() {
        return this.http.get(this.serviceUrl + `supplierList`);
    }

    supplierDetailsById(id) {
        // return this.http.post(this.serviceUrl + `fullsupplierDetails`, param);
        let Url = this.serviceUrl + `fullsupplierDetails`;
        return this.http.post<any>(Url, { id: id })
            .pipe(map(data => {
                if (data) {
                    sessionStorage.setItem('currentUser1', JSON.stringify(data));
                }
                //   return JSON.parse(sessionStorage.getItem('currentUser1'));
                return data;
            }));
    }

    getSupplierById() {
        return JSON.parse(sessionStorage.getItem('currentUser1'));
    }

    updatetUserID(uuid) {
        //userID = uuid;
        sessionStorage.setItem('userID', uuid);
    }

    uploadInvoice(file) {
        return this.http.post(this.serviceUrl + `generateInvoice`, file);
    }

    SupplierReport() {
        return this.http.get(this.serviceUrl + `supplierReport`);
    }

    /* Jitendra Started Code Here 10-10-2023 */
    uploadInvoiceCheckValidation(file): Observable<any> {
        //console.log(this.serviceUrl + `validateremitee`);
        return this.http.post(this.serviceUrl + `validateremitee`, file);
    }

    getRemittanceLogList(name: any): Observable<any> {
        //console.log(this.serviceUrl + `remittanceloglist`);
        //return this.http.get(this.serviceUrl + `remittanceloglist`);
        let data = { 'name': name }
        return this.http.post(this.serviceUrl + `remittanceloglist`, data);
    }

    getLogList(name: any): Observable<any> {
        let data = { 'name': name }
        return this.http.post(this.serviceUrl + `loginloglist`, data);
    }

    getDocumentDiversityHistory(userId: any, position: any): Observable<any> {
        let data = { 'userId': userId, position: position }
        return this.http.post(this.serviceUrl + `document-history-diversity`, data);
    }

    getFinanceHistorydocW9(userId: any): Observable<any> {
        let data = { 'userId': userId }
        return this.http.post(this.serviceUrl + `document-history-finance-docW9`, data);
    }

    getFinanceHistorydocTaxCA590(userId: any): Observable<any> {
        let data = { 'userId': userId }
        return this.http.post(this.serviceUrl + `document-history-finance-docTaxCA590`, data);
    }

    getFinanceHistorydocMSA(userId: any): Observable<any> {
        let data = { 'userId': userId }
        return this.http.post(this.serviceUrl + `document-history-finance-docMSA`, data);
    }

    getFinanceHistorydocInsuranceWaiver(userId: any): Observable<any> {
        let data = { 'userId': userId }
        return this.http.post(this.serviceUrl + `document-history-finance-docInsuranceWaiver`, data);
    }

    getFinanceHistorydocYaleCertificate(userId: any): Observable<any> {
        let data = { 'userId': userId }
        return this.http.post(this.serviceUrl + `document-history-finance-docYaleCertificate`, data);
    }

    getFinanceHistorydocYaleServiceAgreement(userId: any): Observable<any> {
        let data = { 'userId': userId }
        return this.http.post(this.serviceUrl + `document-history-finance-docYaleServiceAgreement`, data);
    }

    getFinanceHistorydocUserConfiguration(userId: any): Observable<any> {
        let data = { 'userId': userId }
        return this.http.post(this.serviceUrl + `document-history-finance-docUserConfiguration`, data);
    }

    getFinanceHistorydocVoidCheck(userId: any): Observable<any> {
        let data = { 'userId': userId }
        return this.http.post(this.serviceUrl + `document-history-finance-docVoidCheck`, data);
    }

    getFinanceHistorydocDepositAgreement(userId: any): Observable<any> {
        let data = { 'userId': userId }
        return this.http.post(this.serviceUrl + `document-history-finance-docDepositAgreement`, data);
    }

    logoutService(name: any): Observable<any> {
        let data = { 'name': name }
        return this.http.post(this.serviceUrl + `logout`, data);
    }

    getRemittanceActiveLogList(name: any): Observable<any> {
        let data = { 'name': name }
        return this.http.post(this.serviceUrl + `activeloginloglist`, data);
    }
    /* End Jitendra Code Here */

}
