import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup , Validators } from '@angular/forms';
import { AlertService, UserService } from '../services/index';
import {ActivatedRoute, Router} from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  submitted = false;
  loading = false;
  registerForm: FormGroup;
  hide: any;
  showSpinner: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      companyName :  ['', Validators.pattern('[a-zA-Z ]*')],
      personName : ['', Validators.pattern('[a-zA-Z ]*')],
      phoneNumber : ['', [Validators.required, Validators.maxLength(10)]],
      emailId : ['', [Validators.required, Validators.email]],
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.loading = true;
    this.userService.register(this.registerForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.alertService.success('Registration successful', true);
          this.router.navigate(['/login']);
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        });
  }
}
